import * as z from 'zod';

export const scheduleSchema = z.object({
  date: z.string(),
  message: z.string(),
  status: z.string(),
  users: z.array(
    z.object({
      name: z.string(),
      mobile: z.string().min(10, 'Mobile should have 10 characters').max(10, 'Mobile should have 10 characters')
    })
  )
});

export const videoSchema = z.object({
  uuid: z.string(),
  userUuid: z.string(),
  compositionId: z.string(),
  compositionType: z.string(),
  views: z.string(),
  status: z.string(),
  data: z.any().optional(),
  schedules: scheduleSchema.optional()
});
