import { addHours } from 'date-fns';
import { create } from 'zustand';
import { immerMiddleware } from '../services/Utils';

export const defaultSchedules = {
  date: addHours(new Date(), 1),
  message: '',
  status: 'PENDING',
  users: [{ name: '', mobile: '' }]
};

const videoStore = set => ({
  video: {
    data: { type: 'web', language: 'english', font: 'Default' },
    schedules: {}
  },
  template: {},
  templateVideos: [],
  setTemplate: template =>
    set(state => {
      state.template = template;
    }),
  resetTemplate: () =>
    set(state => {
      state.template = {};
    }),
  setVideoData: (key: string, value: string) =>
    set(state => {
      state.video.data[key] = value;
    }),
  setSchedulesData: (key: string, value: string) =>
    set(state => {
      state.video.schedules[key] = value;
    }),
  setSchedulesUserData: (index: number, key: string, value: string) =>
    set(state => {
      state.video.schedules.users[index][key] = value;
    }),
  setDefaultSchedules: schedules =>
    set(state => {
      state.video.schedules = schedules;
    }),
  setDefaultVideoData: data =>
    set(state => {
      state.video.data = data;
    }),
  setVideo: video =>
    set(state => {
      state.video = video;
    }),
  resetVideoData: () =>
    set(state => {
      state.video = { data: {} };
    }),
  setCompositionId: compositionId =>
    set(state => {
      state.video.compositionId = compositionId;
    }),
  setCompositionType: compositionType =>
    set(state => {
      state.video.compositionType = compositionType;
    })
});

export const useVideoStore = create(immerMiddleware(videoStore));
