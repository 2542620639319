import { Suspense } from 'react';
import { NLoading } from 'nayan';
import { compositionMapping } from '@/ui/services/TemplatesMappingService.ts';
import { Player } from '@remotion/player';

interface Props {
  template: any;
  video: any;
  isFullScreen?: boolean;
}

const VideosPlayer = (props: Props) => {
  const { template, video, isFullScreen = false } = props;
  const type = video?.data?.type || 'web';

  if (!template?.platforms || !video?.data) {
    return <div className="p-3 text-center">Video not available!</div>;
  }

  return (
    <Suspense fallback={<NLoading />}>
      <Player
        fps={30}
        loop
        controls
        autoPlay={false}
        alwaysShowControls
        inputProps={video.data}
        component={compositionMapping[template.sku + '-' + type]}
        className={
          isFullScreen ? '!w-full !max-w-full !max-h-full !w-screen !h-screen' : '!w-full !max-w-full !h-[250px] md:!h-[calc(100vh_-_109px)]'
        }
        durationInFrames={template.platforms[type].compositionDuration}
        compositionWidth={template.platforms[type].compositionWidth}
        compositionHeight={template.platforms[type].compositionHeight}
      />
    </Suspense>
  );
};

export default VideosPlayer;
