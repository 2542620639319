import { useEffect } from 'react';
import { THEMES, useLocalStorage } from 'nayan';
import { useSearchParams } from 'react-router-dom';
import { useAppStore } from '@/shared/stores/AppStore.ts';
import VideosPlayer from '@/ui/components/templates/VideosPlayer.tsx';

const VideosView = () => {
  const [qParams]: any = useSearchParams();
  const theme = qParams.get('theme');
  const template = JSON.parse(qParams.get('template'));
  const video = JSON.parse(qParams.get('video'));
  const [_, setTheme] = useLocalStorage('THEME');

  useEffect(() => {
    setTheme(theme || THEMES.LIGHT);
    useAppStore.setState({ hideWrapper: true });
  }, []);

  return (
    <div className="box-border" style={!!video?.data?.font ? { fontFamily: video.data.font } : {}}>
      <VideosPlayer template={template} video={video} isFullScreen={true} />
    </div>
  );
};

export default VideosView;
