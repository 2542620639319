import { NSelect } from 'nayan';
import { topTextFonts } from '@/ui/services/WebUtils.ts';

interface Props {
  language: string;
  selected: string;
  isDisabled?: boolean;
  onChange: (font: string) => void;
}

export const FontPicker = (props: Props) => {
  const { language, selected, isDisabled = false } = props;
  const options = topTextFonts[language]?.map(font => ({ label: font.family, value: font.family })) || [];
  const defaultValue = selected ? { label: selected, value: selected } : options[0];

  const onChange = async value => {
    if (value.value === 'Default') {
      props.onChange('');
      return;
    }
    props.onChange(value.value);
  };

  if (typeof window === 'undefined') {
    return null;
  }

  return <NSelect label="Choose font" options={options} value={defaultValue} onChangeOptions={onChange} isDisabled={isDisabled} />;
};
