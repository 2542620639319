import { useEffect, useState } from 'react';
import { AlignJustify, Bell, MoonStar, Sun } from 'lucide-react';
import { NButton, NSheet, THEMES, useLocalStorage } from 'nayan';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { isLogin } from '@/shared/services/AuthService';

const HeaderMenu = () => {
  const [theme, setTheme] = useLocalStorage('THEME', '');

  const toggleTheme = () => {
    const newTheme = theme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK;
    setTheme(newTheme);
  };

  return (
    <div className="w-full flex flex-col md:flex-row justify-between items-center">
      <ul className="w-full md:w-auto flex flex-col md:flex-row items-center mx-auto">
        <li className="w-full md:w-auto">
          <NavLink className="nav-link text-base font-medium text-text px-3 py-3 block md:inline" to="/">
            Home
          </NavLink>
        </li>
        <li className="w-full md:w-auto">
          <NavLink className="nav-link text-base font-medium text-text px-3 py-3 block md:inline" to="/templates">
            Templates
          </NavLink>
        </li>
        <li className="w-full md:w-auto">
          <NavLink className="nav-link text-base font-medium text-text px-3 py-3 block md:inline" to="/about">
            About Us
          </NavLink>
        </li>
      </ul>
      <div className="w-full md:w-auto form-inline md:text-center md:justify-center items-center mt-5 md:mt-0 flex flex-row">
        {theme !== THEMES.DARK && (
          <MoonStar tabIndex={0} role="button" className="w-6 h-6 text-text mx-2 md:my-2 mr-4 theme-toggle" onClick={toggleTheme} />
        )}
        {theme === THEMES.DARK && (
          <Sun tabIndex={0} role="button" className="w-6 h-6 text-text mx-2 md:my-2 mr-4 theme-toggle" onClick={toggleTheme} />
        )}
        {!isLogin() && (
          <>
            <Link to="/signup" className="mr-3">
              <NButton
                tabIndex={-1}
                className="px-4 bg-green-700 dark:bg-green-500 hover:bg-green-700 dark:hover:bg-green-500 border-green-700 dark:border-green-500">
                Signup
              </NButton>
            </Link>
            <Link to="/login">
              <NButton tabIndex={-1} className="px-4">
                Login
              </NButton>
            </Link>
          </>
        )}
        {isLogin() && (
          <>
            <NavLink to="/account/notifications" className="nav-link mr-4">
              <Bell className="w-6 h-6 text-text" />
            </NavLink>
            <Link to="/account">
              <NButton tabIndex={-1} className="px-4">
                Account
              </NButton>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

const Header = () => {
  const location = useLocation();
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setMenu(false);
  }, [location.pathname]);

  return (
    <header className="bg-card fixed top-0 left-0 right-0 z-10 border-0 border-b border-border">
      <div className="container mx-auto md:p-0 px-3">
        <NSheet isOpen={menu} title="Inyter" onCloseSheet={() => setMenu(false)}>
          <HeaderMenu />
        </NSheet>
        <nav className="flex flex-row py-[8px] justify-between items-center">
          <Link to="/">
            <img src="/logo-small.webp" className="d-inline-block align-top main-logo" alt="Inyter Logo" loading="lazy" width={40} height={40} />
          </Link>
          <div className="block md:hidden p-2" onClick={() => setMenu(true)}>
            <AlignJustify className="w-6 h-6 text-text" />
          </div>
          <div className="w-full hidden md:block">
            <HeaderMenu />
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
