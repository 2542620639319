import { AccordionTypes, NAccordion } from 'nayan';

const Faq = () => {
  const items = [
    {
      title: 'How does the video creation process work on Inyter?',
      message:
        "Inyter simplifies the video creation process. You start by choosing a template that fits your occasion, then customize it by adding your photos, videos, and personalized messages. Once you're happy with the design, Inyter generates the video and allows you to send it directly to your loved ones via platforms like WhatsApp, or save it for future use."
    },
    {
      title: 'What occasions can I use Inyter’s video creation for?',
      message:
        "Inyter offers templates and customization options for a wide range of occasions, including birthdays, weddings, anniversaries, graduations, baby showers, holidays, and more. Whether it's a formal event or a fun celebration, Inyter provides the tools to create video invitations and wishes that suit any occasion."
    },
    {
      title: 'Can I customize the video templates on Inyter?',
      message:
        'Yes, Inyter provides a variety of customizable templates. You can personalize each template by adding your own photos, videos, messages, and other elements to make the invitation or wish truly unique and reflective of your event or celebration.'
    },
    {
      title: 'Do I need any design skills to use Inyter?',
      message:
        'No, Inyter is designed with simplicity in mind. The platform’s intuitive interface makes it easy for anyone to create beautiful and professional-looking video invitations and wishes without needing any prior design experience. Just follow the step-by-step customization process to add your personal touches.'
    },
    {
      title: 'Can I schedule video invitations and wishes in advance?',
      message:
        "Yes, Inyter allows you to schedule your video invitations and wishes to be sent at a specific date and time. This feature is perfect for planning ahead, ensuring that your messages are delivered exactly when you want them to be, even if you're busy or unavailable at that time."
    },
    {
      title: 'Why does Inyter charge money?',
      message:
        'Inyter is free to use with minor limitations, allowing you to create personalized video invitations and wishes at no cost. However, we charge a nominal fee for HD content because generating high-quality videos requires powerful servers and significant resources. Video generation is a costly process that involves heavy server usage, and these fees help us maintain the quality and performance of the platform while ensuring smooth and reliable service for all users.'
    },
    {
      title: 'Can I send invitations and wishes through WhatsApp?',
      message:
        'Yes, Inyter allows you to send your personalized video invitations and wishes directly through WhatsApp once the video is generated. Additionally, you can schedule invitations and wishes to be sent on a specific date and time via WhatsApp, making it easier to plan ahead and ensure timely delivery.'
    },
    {
      title: 'How long does it take to generate a video on Inyter?',
      message:
        'The time it takes to generate a video on Inyter may vary based on the number of users and current traffic. During peak times, it might take a little longer to process your video. However, we are working on adding more servers to speed up the rendering process and handle multiple requests simultaneously, ensuring faster delivery in the future.'
    }
  ];

  return (
    <div className="mt-3 mb-5">
      <h2 className="text-xl mb-3">Frequently Asked Questions</h2>
      <NAccordion type={AccordionTypes.SINGLE} items={items} />
    </div>
  );
};

export default Faq;
