import { Link } from 'react-router-dom';

const Banner = () => {
  return (
    <section className="main bg-card flex items-center justify-center overflow-hidden" id="home">
      <div className="container mx-auto px-3 sm:px-0 py-3 sm:py-0">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
          <div className="flex flex-col justify-center">
            <h1 className="text-2xl my-3 text">Create Video Invitations and Wishes</h1>
            <div className="text-base leading-loose mb-8 text">
              Create stunning online video invitations and heartfelt wishes effortlessly. Personalize your videos with unique templates, music, and
              messages. Perfect for weddings, birthdays, baby showers, and more. Make every occasion special with our easy-to-use video creation
              tools. Share memorable moments with friends and family. Start creating today!
            </div>
            <div className="p-0">
              <Link to="/templates" className="bg-primary text-white rounded border-primary px-3 py-3 hover:bg-primary-dark">
                START CREATING
              </Link>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <img src="/landing.webp" alt="inyter landing image" className="w-[500px] h-auto" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
