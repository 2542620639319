import { useState } from 'react';
import { format } from 'date-fns';
import { Play } from 'lucide-react';
import { NButton, NCard, NConfirmAlert, NInput, NTextarea, useToast } from 'nayan';
import { scheduleSchema } from '@/shared/schemas/schemas.ts';
import { useUpdateVideoMutation } from '@/shared/services/VideosService.ts';
import { useVideoStore } from '@/shared/stores/VideoStore.ts';

interface Props {
  onClose: any;
}

const VideosSchedules = (props: Props) => {
  const toast = useToast();
  const [showAlert, setShowAlert] = useState(false);
  const video = useVideoStore(state => state.video);
  const schedules = useVideoStore(state => state.video.schedules || {});
  const setSchedulesData = useVideoStore(state => state.setSchedulesData);
  const setSchedulesUserData = useVideoStore(state => state.setSchedulesUserData);
  const updateVideoMutation: any = useUpdateVideoMutation();

  const isCompleted = schedules.status === 'COMPLETED';

  const onSubmit = e => {
    e.preventDefault();
    const result = scheduleSchema.safeParse(schedules);
    if (result.success) {
      const finalData = { uuid: video.uuid, userUuid: video.userUuid, schedules };
      updateVideoMutation
        .mutateAsync(finalData)
        .then(() => {
          toast('Video schedules updated successfully.');
        })
        .catch(error => toast(error.message));
    } else {
      toast('Validation failed, please recheck data.');
    }
  };

  const removeSchedule = () => {
    const finalData = { uuid: video.uuid, userUuid: video.userUuid, schedules: {} };
    updateVideoMutation
      .mutateAsync(finalData)
      .then(() => {
        toast('Video schedules removed successfully.');
        props.onClose();
      })
      .catch(error => toast(error.message));
  };

  return (
    <div className="videos-schedules bg-background h-full p-3">
      <NConfirmAlert
        isOpen={showAlert}
        message="This action will delete permanently, Are you sure to delete this video schedule?"
        onResult={result => result && removeSchedule()}
        onClose={() => setShowAlert(false)}
      />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        <NCard className="col-span-1 p-3">
          <div className="text-xl mb-5 -mt-1">Preview message</div>
          <div className="w-full h-[150px] flex justify-center items-center bg-background mb-2 rounded relative">
            <span className="bg-primary rounded-full absolute z-10 my-auto p-2">
              <Play strokeWidth={3} className="w-6 h-6 text-white" />
            </span>
            <img
              src={`/images/templates/${video.compositionId}.webp`}
              alt={video.compositionId}
              className="absolute w-full h-full left-0 right-0 top-0 bottom-0 -z-1 rounded"
            />
          </div>
          <div className="mb-2">
            Dear {(schedules?.users && schedules?.users[0]?.name) || '<user-name>'}, {schedules?.message || '<video-message>'}, Your inyter video is
            now ready for viewing or download at https://www.inyter.com/videos/{video.uuid} .
          </div>
          <div className="text-xs text-right">10:24 PM</div>
        </NCard>
        <NCard className="col-span-1 md:col-span-2 p-3">
          <div className="text-xl mb-5 -mt-1">Schedule video</div>
          <form onSubmit={onSubmit}>
            <NInput
              key="date"
              type="datetime-local"
              label="Schedule date & time"
              value={format(schedules['date'] ? new Date(schedules['date']) : new Date(), "yyyy-MM-dd'T'HH:mm") || ''}
              min={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
              required={true}
              disabled={isCompleted}
              onChange={e => setSchedulesData('date', new Date(e.target.value).toISOString())}
            />
            <NTextarea
              className="mb-3"
              key="message"
              type="datetime-local"
              label="Schedule message"
              value={schedules['message'] || ''}
              required={true}
              disabled={isCompleted}
              onChange={e => setSchedulesData('message', e.target.value)}
            />
            {schedules.users?.map((user, index) => (
              <div className="grid grid-cols-2 gap-3" key={index}>
                <NInput
                  key="name"
                  type="text"
                  label="User name"
                  value={user.name || ''}
                  required={true}
                  disabled={isCompleted}
                  onChange={e => setSchedulesUserData(index, 'name', e.target.value)}
                />
                <NInput
                  key="mobile"
                  type="number"
                  label="User mobile"
                  value={user.mobile || ''}
                  required={true}
                  disabled={isCompleted}
                  onChange={e => setSchedulesUserData(index, 'mobile', e.target.value)}
                />
              </div>
            ))}

            <NButton className="mr-3" type="submit" isLoading={updateVideoMutation.isLoading} disabled={isCompleted}>
              Schedule
            </NButton>

            <NButton
              type="button"
              className="mr-3 bg-red-500 border-red-800 hover:bg-red-700"
              disabled={isCompleted}
              onClick={() => setShowAlert(true)}>
              Remove
            </NButton>

            <NButton type="button" className="mr-3 bg-background border-border hover:bg-background" onClick={() => props.onClose()}>
              Cancel
            </NButton>
          </form>
        </NCard>
      </div>
    </div>
  );
};

export default VideosSchedules;
