import { v4 as uuidv4 } from 'uuid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../hooks/hooksUtils';
import { useInfiniteQueryData } from '../hooks/useInfiniteQueryData';
import { useUserStore } from '../stores/UserStore';
import { useVideoStore } from '../stores/VideoStore';
import { ACTION_TYPES } from '../types/types';
import { DateService } from './DateService';
import { RestService } from './RestService';

export const fetchNotifications = ({ pageParam }) => {
  return RestService.get(`/actions/notifications?page=${pageParam}`);
};

export const useNotificationsData = () => {
  return useInfiniteQueryData([QUERY_KEYS.NOTIFICATIONS], fetchNotifications);
};

export const fetchSaved = ({ pageParam }) => {
  return RestService.get(`/actions/saved?page=${pageParam}`);
};

export const useSavedData = () => {
  return useInfiniteQueryData([QUERY_KEYS.SAVED], fetchSaved);
};

export const fetchComments = ({ queryKey, pageParam }) => {
  return RestService.get(`/actions/comments?videoUuid=${queryKey[1]}&page=${pageParam}`);
};

export const useCommentsData = (videoUuid: string) => {
  return useInfiniteQueryData([QUERY_KEYS.COMMENTS, videoUuid], fetchComments, { enabled: !!videoUuid });
};

export const useAddActionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (action: any) => RestService.post('/actions', action),
    onMutate: (action: any) => onActionMutate(queryClient, action, false)
  });
};

export const useRemoveActionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (action: any) => {
      return RestService.delete(`/actions/${action.videoUuid}/${action.userUuid}/${action.type}${action.uuid ? '?uuid=' + action.uuid : ''}`);
    },
    onMutate: (action: any) => onActionMutate(queryClient, action, true)
  });
};

export const onActionMutate = async (queryClient, action, isRemove) => {
  const queryKey = [QUERY_KEYS.VIDEOS, action.videoUuid];
  await queryClient.cancelQueries(queryKey);
  const oldData = queryClient.getQueryData(queryKey);
  const type = action.type;
  if (!!oldData) {
    queryClient.setQueryData(queryKey, (oldQueryData: any) => {
      const post = { ...oldQueryData.data };
      if (type === ACTION_TYPES.VIDEO_LIKE) {
        post.isLiked = isRemove ? 0 : 1;
        post.likes = isRemove ? --post.likes : ++post.likes;
      } else if (type === ACTION_TYPES.VIDEO_SAVE) {
        post.isSaved = isRemove ? 0 : 1;
        handleSaveMutate(queryClient, post, isRemove);
      } else if (type === ACTION_TYPES.VIDEO_REPORT) {
        post.isReported = isRemove ? 0 : 1;
      } else if (type === ACTION_TYPES.VIDEO_COMMENT) {
        post.comments = isRemove ? --post.comments : ++post.comments;
        onCommentMutate(queryClient, action, isRemove);
      }
      useVideoStore.setState({ video: post });
      return { ...oldQueryData, data: post };
    });
  }
};

export const handleSaveMutate = (queryClient, post, isRemove) => {
  if (isRemove) {
    const queryKey = [QUERY_KEYS.SAVED];
    const oldData = queryClient.getQueryData(queryKey);
    if (!!oldData) {
      queryClient.setQueryData(queryKey, (oldQueryData: any) => {
        return {
          ...oldQueryData,
          pages: oldQueryData.pages.map((pageData: any) => {
            return {
              ...pageData,
              data: pageData.data.filter(item => item.uuid !== post.uuid)
            };
          })
        };
      });
    }
  } else {
    const queryKey = [QUERY_KEYS.SAVED];
    const oldData = queryClient.getQueryData(queryKey);
    if (!!oldData) {
      queryClient.setQueryData(queryKey, (oldQueryData: any) => {
        return {
          ...oldQueryData,
          pages: oldQueryData.pages.map((pageData: any, index: number) => {
            return {
              ...pageData,
              data: index === 0 ? [post, ...pageData.data] : pageData.data
            };
          })
        };
      });
    }
  }
};

export const onCommentMutate = (queryClient, action, isRemove) => {
  const queryKey = [QUERY_KEYS.COMMENTS, action.videoUuid];
  const oldData = queryClient.getQueryData(queryKey);
  if (!!oldData) {
    if (isRemove) {
      queryClient.setQueryData(queryKey, (oldQueryData: any) => {
        return {
          ...oldQueryData,
          pages: oldQueryData.pages.map((pageData: any) => {
            return {
              ...pageData,
              data: pageData.data.filter(item => item.uuid !== action.uuid)
            };
          })
        };
      });
    } else {
      queryClient.setQueryData(queryKey, (oldQueryData: any) => {
        const comment = {
          ...action,
          name: useUserStore.getState().UNAME,
          created: DateService.getCurrentDate()
        };
        return {
          ...oldQueryData,
          pages: oldQueryData.pages.map((pageData: any, index: number) => {
            return {
              ...pageData,
              data: index === 0 ? [comment, ...pageData.data] : pageData.data
            };
          })
        };
      });
    }
  }
};

export const createAction = (type: string, userUuid: string, frndUuid: string, videoUuid: string, comment: string = '') => {
  return Object.assign({}, { type, userUuid, frndUuid, videoUuid, uuid: uuidv4() }, !!comment ? { comment } : {});
};
