import { templates } from '@/shared/services/TemplatesService.ts';
import SubHeader from '@/ui/components/helpers/SubHeader.tsx';
import TagsList from '@/ui/components/tags/TagsList.tsx';

const Templates = () => {
  return (
    <div className="container mx-auto px-3 sm:px-0">
      <SubHeader title="Tags" />
      <div className="text-base mb-8 leading-relaxed">
        Discover a multitude of categories to create unique and personalized video invitations and heartfelt wishes for every occasion. Whether you're
        planning a birthday celebration, a wedding announcement, a baby shower, or a holiday greeting, our platform offers a variety of themed
        templates to suit your needs. From elegant and formal designs to playful and whimsical styles, explore different categories to find the
        perfect template that reflects your event's personality and captures your heartfelt sentiments. Start crafting your memorable video invitation
        or wish today with our diverse range of categories.
      </div>
      {templates.map((template: any) => {
        return (
          <div key={template.sku}>
            <div className="flex flex-row justify-between items-center mb-3 py-2">
              <h2 className="text-lg">{template.name} Tags</h2>
            </div>
            <div className="mb-5 leading-relaxed">{template.details}</div>
            <TagsList tags={template?.tags || []} />
          </div>
        );
      })}
    </div>
  );
};

export default Templates;
