import { useMemo } from 'react';
import { useParams } from 'react-router';
import { getTemplateByTag } from '@/shared/services/TemplatesService.ts';
import SubHeader from '@/ui/components/helpers/SubHeader.tsx';
import { TemplatesItem } from '../templates/TemplatesItem.tsx';

const TagsDetails = () => {
  const params: any = useParams();
  const template: any = useMemo(() => getTemplateByTag(params.tag), []);
  const tag = template.tags.find(tg => tg.sku === params.tag);

  return (
    <div className="container mx-auto px-3 sm:px-0">
      <SubHeader title={`${tag.text} Templates`} />
      <div className="leading-relaxed mb-5">{template.details}</div>
      {!template || (!template.videos.length && <div className="p-10 text-center">No templates available.</div>)}
      {!!template && (
        <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-3 mb-3">
          {template.videos.map((video: any) => (
            <TemplatesItem key={video.sku} template={template} video={video} />
          ))}
        </div>
      )}
    </div>
  );
};

export default TagsDetails;
