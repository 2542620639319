import { useMemo, useState } from 'react';
import { Eye, MoreVertical, PencilRuler, Trash2 } from 'lucide-react';
import { NCard, NConfirmAlert, NMenu, NMenuItem, useToast } from 'nayan';
import { Link, useNavigate } from 'react-router-dom';
import { DateService } from '@/shared/services/DateService';
import { getTemplateVideo } from '@/shared/services/TemplatesService.ts';
import { useDeleteVideoMutation } from '@/shared/services/VideosService';
import { useUserStore } from '@/shared/stores/UserStore';

export interface Props {
  video: any;
}

export const AccountVideosItem = (props: Props) => {
  const { video } = props;
  const toast = useToast();
  const navigate = useNavigate();
  const userUuid = useUserStore(state => state.UUID);
  const [showAlert, setShowAlert] = useState(false);
  const templateData = useMemo(() => getTemplateVideo(video.compositionType, video.compositionId), []);
  const deleteVideoMutation = useDeleteVideoMutation();

  return (
    <div>
      <NConfirmAlert
        isOpen={showAlert}
        message="This action will delete permanently, Are you sure to delete this video?"
        onResult={result =>
          result &&
          deleteVideoMutation
            .mutateAsync(video.uuid)
            .then(() => toast('video deleted successfully.'))
            .catch(error => toast(error.message))
        }
        onClose={() => {
          setShowAlert(false);
          // TODO: remove this once pointer events issue fixed.
          setTimeout(() => document.getElementById('body')?.setAttribute('style', ''), 500);
        }}
      />
      <Link to={`/videos/${video.uuid}`}>
        <NCard className="col-span-1 flex flex-col h-full video-item">
          <div className="relative h-0 overflow-hidden" style={{ paddingTop: '52.3%' }}>
            <img
              src={`/images/templates/${templateData?.sku}.webp`}
              alt={templateData?.name}
              className="absolute top-0 left-0 w-full h-full rounded rounded-b-none"
              loading="lazy"
            />
          </div>
          <div className="py-2 px-2">
            <div className="flex justify-between items-start">
              <div>
                <div className="text-base mb-1 line-clamp-1">{video.data.title}</div>
                <div className="text-sm mb-1 line-clamp-2 h-[40px]">{video.data.description}</div>
              </div>
              <div
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                <NMenu trigger={<MoreVertical className="text-text w-4 h-4 mt-1 video-menu-trigger" />}>
                  <NMenuItem
                    title="View"
                    icon={Eye}
                    onClick={e => {
                      e.preventDefault();
                      navigate(`/videos/${video.uuid}`);
                    }}
                  />
                  {video.userUuid === userUuid && (
                    <NMenuItem
                      title="Edit"
                      icon={PencilRuler}
                      onClick={e => {
                        e.preventDefault();
                        navigate(`/templates/${video.compositionType}/${video.compositionId}/${video.uuid}`);
                      }}
                    />
                  )}
                  {video.userUuid === userUuid && (
                    <NMenuItem
                      title="Delete"
                      icon={Trash2}
                      onClick={e => {
                        e.preventDefault();
                        setShowAlert(true);
                      }}
                    />
                  )}
                </NMenu>
              </div>
            </div>
            <div className="text-muted text-xs">
              {!!video.name && <span>{video.name} &middot;</span>}
              {DateService.format(video.created)}
            </div>
          </div>
        </NCard>
      </Link>
    </div>
  );
};
