import { useEffect } from 'react';
import { NTheme, THEMES, useLocalStorage } from 'nayan';
import { Navigate, Route, Routes } from 'react-router-dom';
import VideosView from '@/ui/components/view/VideosView.tsx';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import About from './components/about/About';
import Account from './components/account/Account';
import Auth from './components/auth/Auth';
import Reset from './components/auth/Reset';
import Verify from './components/auth/Verify';
import { Meta } from './components/helpers/Meta';
import ProtectedRoutes from './components/helpers/ProtectedRoute';
import Wrapper from './components/helpers/Wrapper';
import Home from './components/home/Home';
import CancellationPolicies from './components/policies/CancellationPolicies';
import PrivacyPolicies from './components/policies/PrivacyPolicies';
import TermsOfUse from './components/policies/TermsOfUse';
import Tags from './components/tags/Tags.tsx';
import TagsDetails from './components/tags/TagsDetails';
import Templates from './components/templates/Templates';
import TemplatesType from './components/templates/TemplatesType';
import Videos from './components/templates/Videos';
import VideosNew from './components/templates/VideosNew';
import { setUserFromStorage } from './services/WebUtils';
import { restClient } from './shared/config/axios';

console.error = () => {};

const App = () => {
  const [uuid] = useLocalStorage('UUID', '');
  const [theme] = useLocalStorage('THEME', THEMES.LIGHT);

  useEffect(() => {
    setUserFromStorage();
  }, [uuid]);

  return (
    <QueryClientProvider client={restClient}>
      <NTheme theme={theme}>
        <Meta />
        <Wrapper>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<About />} />
            <Route path="/login" element={<Auth />} />
            <Route path="/signup" element={<Auth />} />
            <Route path="/forgot-password" element={<Auth />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policies" element={<PrivacyPolicies />} />
            <Route path="/cancellation-policies" element={<CancellationPolicies />} />
            <Route path="/reset-password/:userUuid/:resetUuid" element={<Reset />} />
            <Route path="/verify/:userUuid/:resetUuid" element={<Verify />} />
            <Route path="/view" element={<VideosView />} />

            <Route path="/templates">
              <Route index element={<Templates />} />
              <Route path=":type">
                <Route index element={<TemplatesType />} />
                <Route path=":video">
                  <Route index element={<VideosNew />} />
                  <Route path=":uuid" element={<VideosNew />} />
                </Route>
              </Route>
            </Route>

            <Route path="/tags">
              <Route index element={<Tags />} />
              <Route path=":tag" element={<TagsDetails />} />
            </Route>

            <Route path="/videos">
              <Route index element={<Videos />} />
              <Route path=":uuid" element={<Videos />} />
            </Route>

            <Route element={<ProtectedRoutes />}>
              <Route path="/account">
                <Route index element={<Account />} />
                <Route path=":tab" element={<Account />} />
              </Route>
            </Route>

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Wrapper>
        <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
      </NTheme>
    </QueryClientProvider>
  );
};

export default App;
