import { useState } from 'react';
import { NButton, NCard, NConfirmAlert, useToast } from 'nayan';
import { useNavigate } from 'react-router-dom';
import { deleteAccount } from '@/shared/services/AuthService';
import { RestService } from '@/shared/services/RestService';
import { StorageService } from '@/ui/services/StorageService';
import { setUserFromStorage } from '@/ui/services/WebUtils';
import { QueryCache, useQueryClient } from '@tanstack/react-query';
import SubHeader from '../helpers/SubHeader';

export const AccountDeleteData = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const restCache = new QueryCache();
  const queryClient = useQueryClient();
  const [showAlert, setShowAlert] = useState(false);

  const onDelete = () => {
    return deleteAccount({}).then(async () => {
      toast('Your account deleted successfully.');
      setUserFromStorage();
      navigate('/login', { replace: true });
      await restCache.clear();
      await queryClient.clear();
      RestService.setToken('');
      StorageService.unsetAll();
    });
  };

  return (
    <div>
      <NConfirmAlert
        isOpen={showAlert}
        message="Are you sure to delete your account?"
        onResult={result => result && onDelete()}
        onClose={() => setShowAlert(false)}
      />
      <SubHeader title="Delete Account & Data" />
      <NCard className="p-3">
        <div className="mb-3">
          Deleting account will remove your account permanently. this will also remove all the data related to account and subscriptions related to
          your account.
        </div>
        <NButton onClick={() => setShowAlert(true)}>Delete Account</NButton>
      </NCard>
    </div>
  );
};
