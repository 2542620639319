import axios, { AxiosError, AxiosResponse } from 'axios';
import { QueryClient } from '@tanstack/react-query';
import { StorageService } from '../../services/StorageService.ts';
import { createError } from '../services/Utils';
import { CONFIG } from './config';

export const instance = axios.create({
  baseURL: CONFIG.API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    AppName: CONFIG.APP_NAME as string,
    AppSource: CONFIG.SOURCE as string,
    Authorization: typeof localStorage !== 'undefined' ? 'Bearer ' + StorageService.get('TOKEN') : ''
  }
});

instance.interceptors.request.use(
  (request: any) => {
    // !isProd() && console.log('REQ', request);
    return request;
  },
  (error: AxiosError) => {
    // !isProd() && console.log('ERROR', error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError | any) => {
    if (error && error.response && !!error.response.data) {
      return handleError(error.response.data);
    } else {
      const customError = createError(500, 'Something went wrong, try again in some time.');
      return handleError(customError);
    }
  }
);

export interface HTTP_ERROR {
  status: number;
  message: string;
}

export const handleError = (error: HTTP_ERROR) => {
  return Promise.reject(error);
};

export const restClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  }
});
