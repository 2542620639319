import { Navigate } from 'react-router-dom';
import { isLogin } from '@/shared/services/AuthService';
import { AUTH_TABS } from '@/shared/types/types';
import Forgot from './Forgot';
import Login from './Login';
import Signup from './Signup';
import Social from './Social';

const Auth = () => {
  const getDefaultTab = () => {
    if (typeof window === 'undefined') return;
    if (window.location.href.includes('/signup')) {
      return AUTH_TABS.SIGNUP;
    } else if (window.location.href.includes('/forgot-password')) {
      return AUTH_TABS.FORGOT;
    } else {
      return AUTH_TABS.LOGIN;
    }
  };

  if (isLogin()) {
    return <Navigate to="/account" />;
  }

  return (
    <div className="card container mx-auto px-3 md:px-0 mb-3">
      <div className="w-full md:max-w-[450px] mx-auto py-5 md:py-10">
        {typeof window != 'undefined' && <Social />}
        {getDefaultTab() === AUTH_TABS.LOGIN && <Login />}
        {getDefaultTab() === AUTH_TABS.SIGNUP && <Signup />}
        {getDefaultTab() === AUTH_TABS.FORGOT && <Forgot />}
      </div>
    </div>
  );
};

export default Auth;
