import { Link } from 'react-router-dom';

interface Props {
  title: string;
  text: string;
  icon: any;
  link?: string;
}

const FeaturesItem = (props: Props) => {
  const Icon = props.icon;
  return (
    <div className="col-span-1 justify-center features-item">
      <div className="text-base text mb-2 flex">
        <span className="mr-3 text-primary">
          <Icon size="1.3em" />
        </span>
        {!props.link && <span className="font-medium">{props.title}</span>}
        {!!props.link && (
          <Link to={props.link} target="_blank" className="no-underline cursor-pointer text-text">
            <span className="font-medium">{props.title}</span>
          </Link>
        )}
      </div>
      <div className="text-sm text leading-relaxed">{props.text}</div>
    </div>
  );
};

export default FeaturesItem;
